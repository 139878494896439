// NeueFrutigerArabic Thin
@font-face {
  font-family: 'NeueFrutigerArabic';
  src: url('#{$netstorage-fonts-path}arabic/NeueFrutigerArabic/NeueFrutigerArabicThin.woff2')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'NeueFrutigerArabic';
  src: url('#{$netstorage-fonts-path}arabic/NeueFrutigerArabic/NeueFrutigerArabicThin.woff2')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

// NeueFrutigerArabic Medium
@font-face {
  font-family: 'NeueFrutigerArabic';
  src: url('#{$netstorage-fonts-path}arabic/NeueFrutigerArabic/NeueFrutigerArabicMedium.woff2')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'NeueFrutigerArabic';
  src: url('#{$netstorage-fonts-path}arabic/NeueFrutigerArabic/NeueFrutigerArabicMedium.woff2')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}
