html,
body {
  font-family: $me-primary-font;
}

.cs-page {
  &__content-item {
    margin: 15px auto;
    .contact-us {
      &__salesforce-title {
        text-align: center;
        @include breakpoint($medium-up) {
          text-align: #{$ldirection};
        }
      }
    }
    .error_messages {
      margin: 0 0 25px 0;
      color: $color-red;
    }
    input[type='text'],
    input[type='email'],
    textarea {
      width: 100%;
      &.error {
        border-color: $color-red;
        color: $color-red;
      }
      &.adpl--processed {
        &:required {
          &:invalid {
            &:empty {
              border-color: $color-off-black;
            }
          }
        }
      }
    }
    .form-item {
      &__first-name,
      &__last-name {
        @include breakpoint($medium-up) {
          width: 50%;
          float: #{$ldirection};
          label {
            line-height: 4;
          }
        }
      }
      &__first-name {
        @include breakpoint($medium-up) {
          padding-#{$rdirection}: 10px;
        }
      }
      &__last-name {
        @include breakpoint($medium-up) {
          padding-#{$ldirection}: 10px;
        }
      }
      &__email {
        label {
          @include breakpoint($medium-up) {
            line-height: 2.8;
          }
        }
      }
    }
  }
}

html[lang='ar-ME'] {
  .cs-page {
    &__content-item {
      .contact-us {
        &__salesforce-title {
          @include breakpoint($medium-up) {
            text-align: #{$rdirection};
          }
        }
      }
    }
  }
}
